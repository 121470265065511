import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Helmet from "react-helmet"
import "../styles/style.scss"

const Layout = props => {
  return (
    <div class="container">
      <Helmet>
        <title>Jitske... knipt</title>
        <meta name="description" content="Jitske Knipt" />
        <link rel="stylesheet" href="https://use.typekit.net/bef8byr.css" />
        <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
      </Helmet>
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
