import React from "react"
import insta from "../../static/wakko-gent-insta.png"

const Welcome = props => {
  return (
    <div class="welcome">
      <div className="welcome__hours">
        <p>
          ma –<span>gesloten</span>
          <br />
          di –<span>12.00 – 20.00</span>
          <br />
          wo – vr –<span>10.00 - 18.00</span>
          <br />
          za –<span>9.00 – 16.30</span>
          <br />
          zo –<span>gesloten</span>
        </p>
      </div>
      <div className="welcome__intro-text">
        <p>
          <strong> Jitske… knipt?</strong> Op droog haar. Op deze manier kunnen
          we rekening houden met de natuurlijke val van jouw haar en op deze
          manier het perfecte kapsel creëren!
        </p>
        <br />
        <p>
          <strong>Jitske… kleurt?</strong> Je kan bij ons voor elke soort
          kleuring terecht. Voor een kleurconsultatie kan je altijd even
          binnenspringen of ons telefonisch contacteren! The sky is the limit!
        </p>
        <br />
        <p>
          <strong>Jitske… is bewust.</strong> We gebruiken & verkopen vegan
          producten. Onze flessen zijn of 100% biodegradable of gemaakt van 100%
          ocean waste plastic! Zo dragen wij ons steentje bij aan een betere
          wereld.
        </p>
        <br />
        <p>
          <strong>Jitske.. maakt een afspraak. </strong>Online kan je enkel
          inboeken voor snit. Eerst kies je het salon, dan kan je voor 1 van
          onze getalenteerde kappers kiezen! Voor elke kleuring contacteer je
          ons telefonisch of via mail. Kleuren kan dus niet online worden
          ingeboekt.
        </p>
        <br />
        <p>
          <strong>Jitske… verwelkomt </strong>jullie graag binnenkort!
        </p>
      </div>
      <div className="welcome__pricing">
        <p>
          stylist –
          <em>
            <strong>junior </strong>
          </em>
          –
          <em>
            <strong>stylist </strong>
          </em>
          –
          <em>
            <strong>topstylist </strong>
          </em>
          -
          <em>
            <strong>Jitske</strong>
          </em>
        </p>
        <p>
          dames – <strong>45,00</strong> – <strong>49,00</strong> – 
          <strong> 54,50</strong> - <strong>60,00 EUR</strong>
        </p>
        <p>
          heren – <strong>33,00</strong> – <strong>38,50</strong> -
          <strong> 44,00</strong> - <strong>50,00 EUR</strong>
        </p>
        <p>
          non-binair – <strong>34,00</strong> – <strong>40,00</strong> -
          <strong> 45,00</strong> - <strong>55,00 EUR</strong>
        </p>
        <p>Snit kindje tot en met 11 - <strong>25,00 EUR</strong></p>
      </div>
      <div className="welcome__links">
        <p>
          <a
            href="https://online-jitskeknipt.flexxis.nl"
            target="_blank"
            rel="noreferrer"
          >
            online afspraak maken
          </a>
        </p>
        <p>
          <a href="tel:+32092334803">bel ons</a>
        </p>
      </div>
      <div className="row">
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image1} alt="" />
          </div>
        </div>
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image2} alt="" />
          </div>
        </div>
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image3} alt="" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image4} alt="" />
          </div>
        </div>
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image5} alt="" />
          </div>
        </div>
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image6} alt="" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image7} alt="" />
          </div>
        </div>
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image8} alt="" />
          </div>
        </div>
        <div className="welcome__images col-sm-4">
          <div className="visual">
            <img src={props.image9} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome
