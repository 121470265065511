import React from "react"
import Layout from "../components/Layout"
import Welcome from "../components/Welcome"
import { graphql } from "gatsby"

const Index = ({ data }) => {
  {
    data.allContentfulAsset.edges.map(({ node }) => <tr>{node}</tr>)
  }

  console.log(data.allContentfulAsset.edges[0].node.file.url)

  return (
    <div>
      <Layout>
        <Welcome
          image1={data.allContentfulAsset.edges[0].node.file.url}
          image2={data.allContentfulAsset.edges[1].node.file.url}
          image3={data.allContentfulAsset.edges[2].node.file.url}
          image4={data.allContentfulAsset.edges[3].node.file.url}
          image5={data.allContentfulAsset.edges[4].node.file.url}
          image6={data.allContentfulAsset.edges[5].node.file.url}
          image7={data.allContentfulAsset.edges[6].node.file.url}
          image8={data.allContentfulAsset.edges[7].node.file.url}
          image9={data.allContentfulAsset.edges[8].node.file.url}
        />
      </Layout>
    </div>
  )
}

export default Index

export const query = graphql`
  query MyQuery {
    allContentfulAsset {
      edges {
        node {
          file {
            url
          }
        }
      }
    }
  }
`
