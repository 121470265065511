import React from "react"
import facebook from "../../static/facebook.svg"
import instagram from "../../static/instagram.svg"

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__e-mail">
        <a href="mailto:info@jitskeknipt.be">info@jitskeknipt.be</a>
      </div>
      <div className="footer__social">
        <a
          href="https://www.facebook.com/wakkogent"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebook-icon" />
        </a>
        <a
          href="https://www.instagram.com/wakkogent/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram-icon" />
        </a>
      </div>
    </div>
  )
}

export default Footer
