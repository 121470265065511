import React from "react"
import logo from "../../static/logo.png"

const Header = () => {
  return (
    <div class="header">
      <div class="header__title">
        <a class="header__logo" href="/">
          <img src={logo} alt="" />
        </a>
      </div>
      <div className="header__contact-details">
        <p className="header__contact-details__address">
          <a href="https://www.google.be/maps/place/De+Wakko+Kapper+Gent/@51.0508967,3.7273458,17z/data=!4m12!1m6!3m5!1s0x47c3714f220b9b2f:0xaa4bf97342edad95!2sDe+Wakko+Kapper+Gent!8m2!3d51.0508933!4d3.7295398!3m4!1s0x47c3714f220b9b2f:0xaa4bf97342edad95!8m2!3d51.0508933!4d3.7295398">
            Vlaanderenstraat 35
          </a>
        </p>
        <p className="header__contact-details__city">9000 Gent</p>
        <p className="header__contact-details__phone">
          <a href="tel:+32092334803">09 233 48 03</a>
        </p>
      </div>
      <div className="header__contact-details">
        <p className="header__contact-details__address">
          <a href="https://goo.gl/maps/hYED7F9sSJft5FWS8">Brabantdam 17</a>
        </p>
        <p className="header__contact-details__city">9000 Gent</p>
        <p className="header__contact-details__phone">
          <a href="tel:+3293835367">09 383 53 67</a>
        </p>
      </div>
      <div className="header__contact-details">
        <p className="header__contact-details__address">
          <a href="https://www.google.be/maps/place/De+Wakko+Kapper/@51.2027165,4.3939371,15z/data=!4m8!1m2!2m1!1swakko+kapper+antwerpen!3m4!1s0x47c3f6f1e57b1947:0xa4762c017602d91b!8m2!3d51.2106475!4d4.4018183">
            Kasteelpleinstraat 37
          </a>
        </p>
        <p className="header__contact-details__city">2000 Antwerpen</p>
        <p className="header__contact-details__phone">
          <a href="tel:+32032334604">03 233 46 04</a>
        </p>
      </div>
    </div>
  )
}

export default Header
